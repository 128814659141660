@import "node_modules/sos-common-ui/styles/style.scss";
@import "node_modules/sos-common-ui/styles/sos-cookiebot.css";
@import "flag-icons/css/flag-icons.min.css";


$bottom-red-line-height: .5rem;
.header-progress-bar {
  width: 100%;
}

html, body {
  height: 100%;
  overflow-x: visible;
  font-family: Arial, "Effra", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-font-smoothing: antialiased;
  -o-font-smoothing: antialiased;
  margin: 0 !important;
  background-color: rgb(var(--scu-backdrop));
  position: relative;
}

.flex-layout {
  background-color: transparent;
}

scu-burger-menu {
  .icon-open {
      width: 28px !important;
      height: 28px !important;
    }
}

.setModalDialogSize .modal-dialog {
  max-width: 334px;
  margin: auto;
}

.sos-logo {
  width:140px;
  height:35px;
}

@media (max-width: 767.98px) {
  @include main-content {
    width: 90%;
  }
  @include main-element {
    width: 90%;
  }
  @include width-restricted {
    width: 90% !important;
  }
}

// Medium devices (tablets, 768px and up)
@media (min-width: 767.98px) {
  @include main-content {
    width: 37.5rem;
  }
  @include main-element {
    width: 37.5rem;
  }
  @include width-restricted {
    width: 37.5rem !important;
  }

  .btn.filled-button, .btn.back-button{
    min-width: 140px !important;
    min-height: 50px;
  }
}

.pt-50 {
  padding-top: 2.75rem;
}

.font-14 {
  font-size: 14px;
}

.modal-footer a {
  text-decoration: underline;
}

.element-outlet {
  padding-left: 0;
  margin: 0 auto;
}

.mb-30 {
  margin-bottom: 1.875rem;
}

.btn.back-button {
  background-color: rgb(var(--scu-backdrop-light));
}

.btn.filled-button, .btn.back-button{
  min-width: 120px !important;
  min-height: 45px;
}

.summary-box {
  border: 0.063rem solid rgb(var(--scu-secondary-faded));
  background-color: rgb(var(--scu-backdrop-light));
  border-radius: 0.313rem;
  padding-left: 1.25rem;
  padding-right: 1.25rem;
  padding-top: 1.125rem;
}

.line-height-32 {
  line-height: 2rem;
}

ngb-modal-backdrop.modal-backdrop{
  background-color: rgba($scu_backdrop_dark_rgb, var(--bs-bg-opacity, 0.6));
  --bs-backdrop-opacity: 1;
}

body:has(app-root.freeze-scrolling){
  overflow: hidden !important;
  padding-right: 8px !important;
}

.flex-layout > div:has(> router-outlet) > router-outlet + * {
  padding-top: 1.25rem !important;
}
